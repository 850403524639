import React, { useState } from 'react'
import CommonButton from '../../../../common/CommonButton.js'
import DeleteConfirmationModal from './DeleteConfirmationModal.js';
import { isViewPermissionValid } from '../../../../../utils/common.js';

const DeleteApplication = ({ title }) => {
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

  const handleDeleteNotificationModal = () => {
    setOpenDeleteConfirmationModal(!openDeleteConfirmationModal);
  }

  return (
    <div className='pl-6'>
      <p className='pl-2 text-lg font-semibold'>Delete Application</p>
      <p className='pl-2 text-base mt-3 text-slate-600'>Delete application <span className='font-bold'>{title}</span>. This action cannot be undone.
      </p>
      <CommonButton
        onClick={isViewPermissionValid? undefined: handleDeleteNotificationModal}
        disabled={isViewPermissionValid}
        text="Delete Application"
        background="bg-red-200 text-red-500 mt-4"
      />
      {openDeleteConfirmationModal && (<DeleteConfirmationModal open={openDeleteConfirmationModal} close={handleDeleteNotificationModal} />)}
    </div>

  );
};
export default DeleteApplication