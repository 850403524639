import * as React from "react";
import {
  Box,
  Drawer as MuiDrawer,
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  List,
  Tooltip,
} from "@mui/material";
import { DragHandle } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../SideNavBar/SideNav.css";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  zIndex: 1301, // Ensures DrawerHeader is above the AppBar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#97180C", // Set the Drawer background color
  zIndex: 1300, // Higher z-index to overlap the AppBar
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      backgroundColor: "#36454F", // Set the Drawer background color
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#36454F", // Set the Drawer background color
    },
  }),
}));

const SideNav = ({ heading, children }) => {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Use the hook here
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const menuItems = [
    {
      text: "Dashboard",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_dashboard.png`}
          alt="dashboardIcon"
          className="w-6 h-6"
        />
      ),
      path: "/",
    },
    {
      text: "Transactions",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_transaction.png`}
          alt="tarnsactionsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/transactions",
    },
    {
      text: "Centralised Wallets",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/centralised-wallet.png`}
          alt="walletsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/centralised-wallets",
    },
    {
      text: "Fee Wallets",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_fee_wallet.png`}
          alt="feeWalletsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/fee-wallets",
    },
    {
      text: "Wallets",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_wallet.png`}
          alt="walletsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/wallets",
    },
    {
      text: "Sweeps",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_sweeps.png`}
          alt="sweepsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/sweeps",
    },
    {
      text: "Global Settings",
      icon: (
        <img
          src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_setting.png`}
          alt="settingsIcon"
          className="w-6 h-6"
        />
      ),
      path: "/global-settings",
    },
    {
      text: "Notifications",
      icon: (
        <div className="relative">
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/notifications_nav.png`}
            alt="notificationsIcon"
            className="w-6 h-6"
          />
          <p className="absolute top-0 right-1 w-2 h-2 bg-amber-400 rounded-full"></p>
        </div>
      ),
      path: "/notifications",
    },
  ];

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === path; // Exact match for root (Dashboard)
    }
    return location.pathname.startsWith(path); // Matches if the path starts with '/transactions' (for dynamic routes like /transactions/:id)
  };

  return (
    <Box className="flex">
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          className="nav-drawer-header"
        >
          {open && (
            <>
              <Box
                className="w-48 cursor-pointer"
                onClick={handleOpenClose}
                component="img"
                src="/navImage.png"
                alt="Logo"
              />
            </>
          )}
          {!open && (
            <IconButton onClick={handleOpenClose}>
              <DragHandle className="text-white" fontSize="large" />
            </IconButton>
          )}
        </DrawerHeader>

        <Divider />
        <List className="h-full flex flex-col justify-between">
          <div>
            {menuItems.map(({ text, icon, path }) => {
              const active = isActive(path); // Check if the current path is active
              return (
                <Tooltip key={text} title={!open ? text : ""} placement="right">
                  <ListItem
                    key={text}
                    disablePadding
                    className="px-2.5 mt-1 first:mt-0 text-white nav-list-item"
                  >
                    <ListItemButton
                      component={Link}
                      to={path}
                      className={`nav-open ${active ? "background-color-active" : "background-color-inactive"}`}
                    >
                      <ListItemIcon
                        className={`nav-icon ${open ? "nav-icon-margin-set" : "nav-icon-margin-unset"}`}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        className={`${open ? "opacity-100" : "opacity-0"}`}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              )
            })}
          </div>

          <div>
            <Tooltip title={!open ? "Logout" : ""} placement="right">
              <ListItem
                key={"Logout"}
                disablePadding
                className="px-3 mb-4 nav-list-item"
              >
                <ListItemButton
                  className={`logout-nav-button ${open ? "logout-button-initial" : "logout-button-centre"}`}
                  onClick={logout}
                >
                  <ListItemIcon
                    className={`logout-icon ${open ? "logout-icon-margin-set" : "logout-icon-margin-unset"}`}
                  >
                    <img
                      src={`${imageBaseURL}/images/caas-admin-ui/icons/nav_logout.png`}
                      alt="walletsIcon"
                      className="w-6 h-6"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Logout"}
                    className={`${open ? "opacity-100" : "opacity-0"}`}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </div>
        </List>
      </Drawer>
      <Box
        component="main"
        className="overflow-auto flex-grow p-3 relative h-screen"
      >
        {children}
      </Box>
    </Box>
  );
};

export default SideNav;
