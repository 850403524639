import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, TableCell, Snackbar } from "@mui/material";
import CommonTable from "../../../../CommonTable.js";
import apiRequest from "../../../../../../utils/api.js";
import useDebounce from "../../../../../../customHook/useDebounce.js";
import { useSelector } from "react-redux";
import { getCryptoImage } from "../../../../../../utils/common.js";
import constant from "../../../../../../utils/constant.js";

const FeesTabs = (props) => {
  const { transactionType, data, getFeeSettings } = props;
  const [feeProfileType, setFeeProfileType] = useState("standard");
  const [feeData, setFeeData] = useState([]);
  const [openToast, setOpenToast] = useState("");

  const application = useSelector((state) => state?.globalSettings?.application?.data);
  const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);

  // Memoize the cryptoList array to prevent unnecessary rerenders
  const cryptoList = useMemo(() => [
    { crypto: "BTC" },
    { crypto: "ETH" },
    { crypto: "USDT" },
    { crypto: "USDC" },
    { crypto: "XRP" },
    { crypto: "LTC" },
    { crypto: "TRX" },
    { crypto: "DAI" },
  ], []);

  const profileTypes = [
    { label: "Standard", key: "standard" },
    { label: "VIP", key: "vip" },
    { label: "Super VIP", key: "supervip" },
  ];

  const headers = transactionType === constant?.transactionType?.exchange
    ? [{ label: "Crypto" }, { label: "" }, ...cryptoList?.map((crypto) => ({ label: `${crypto?.crypto}` }))]
    : transactionType === constant?.transactionType?.send ? [{ label: "Currency" }, { label: "Fee's" }, { label: "Gas Fee's" }] : [{ label: "Currency" }, { label: "Fee's" }];

  const handleFeeProfileTypeChange = (item) => setFeeProfileType(item?.key);

  const handleCloseToast = (event, reason) => {
    if (reason !== "clickaway") setOpenToast("");
  };

  useEffect(() => {
    if (transactionType === constant?.transactionType?.exchange) {
      const groupedData = {};

      // Group cryptoList data by crypto
      cryptoList?.forEach((crypto) => {
        if (crypto?.crypto) {  // Only add if crypto is defined
          groupedData[crypto?.crypto] = {};
        }
      });

      // Process and group data based on transactionType and feeProfileType
      data?.[transactionType?.toLowerCase()]?.[feeProfileType]?.forEach((item) => {
        // Ensure that both fromCrypto and toCrypto are valid before adding
        if (item?.fromCrypto && item?.toCrypto) {
          if (!groupedData[item?.fromCrypto]) {
            groupedData[item?.fromCrypto] = {};
          }
          groupedData[item?.fromCrypto][item?.toCrypto] = item?.fee;
        }
      });

      // Filter out any rows with missing or undefined fromCrypto values
      const tableData = Object.keys(groupedData)
        ?.map((fromCrypto) => ({
          fromCrypto,
          ...groupedData[fromCrypto],
        }))
        ?.filter((row) => row?.fromCrypto);  // Filter out rows with undefined fromCrypto
      setFeeData(tableData);
    } else {
      // Filter non-Exchange data and ensure only valid items are included
      const tableData =
        data?.[transactionType?.toLowerCase()]?.[feeProfileType]
          ?.filter((item) => item?.crypto || item?.fromCrypto)
          ?.map((item) => ({
            fromCrypto: item?.crypto || item?.fromCrypto, // Rename `crypto` to `fromCrypto`
            ...item,
          })) || [];

      setFeeData(tableData);
    }
  }, [transactionType, feeProfileType, data, cryptoList]);

  const updateFee = async (fromCrypto, toCrypto, fee, gasFee) => {
    const profileLabel = profileTypes?.find((type) => type?.key === feeProfileType)?.label || "Standard";

    // Prepare the payload conditionally based on isGasFee
    const payload = {
      applicationId: application?._id,
      profile: profileLabel,
      fee: fee || 0,
      gasFee: gasFee || 0,
      transactionType,
      fromCrypto,
      toCrypto: toCrypto || "",
    };
    try {
      const response = await apiRequest("/create-or-update-cypto-fee-setting", "POST", payload);

      setOpenToast(response?.success ? "Fee setting updated successfully" : response?.error);
      if (response?.success) getFeeSettings();
    } catch (error) {
      setOpenToast("Error updating fee setting");
    }
  };

  const debouncedUpdateFee = useDebounce(updateFee, 1500);
 
  const handleFeeChange = useCallback(
    (e, fromCrypto, toCrypto, isGasFee = false) => {
      let inputValue = e?.target?.value;
    
      // Allow only numbers and one decimal point
      if (/[^0-9.]/.test(inputValue)) {
        return; // Prevent invalid characters
      }
    
      // Ensure only one decimal point is allowed
      if ((inputValue?.match(/\./g) || [])?.length > 1) {
        return; // Prevent multiple decimal points
      }
    
      // Handle case of empty input value (e.g., the user deletes everything)
      if (inputValue === "") {
        inputValue = "0";
      }
    
      // Validate the fee to ensure it’s a valid positive number (string comparison)
      // Allow up to 8 decimal places (i.e., for numbers like 0.0001)
      if (new RegExp("^\\d*\\.?\\d{0,8}$")?.test(inputValue)) {
        let newFee = inputValue;
        let fee, gasFee;
    
        // Update feeData and calculate fee/gasFee before state change
        const updatedFeeData = feeData?.map((item) => {
          if (transactionType === constant?.transactionType?.exchange && item?.fromCrypto === fromCrypto?.fromCrypto) {
            fee = newFee;
            return toCrypto
              ? { ...item, [toCrypto]: newFee }
              : { ...item, fee: newFee };
          } else if (transactionType !== constant?.transactionType?.exchange && item._id === fromCrypto?._id) {
            if (isGasFee) {
              gasFee = newFee; // Assign newFee to gasFee
              fee = item?.fee; // Retain the original fee from the item
            } else {
              gasFee = item?.gasFee; // Retain the original gasFee from the item
              fee = newFee; // Update fee with newFee
            }
    
            // Return the updated item with either gasFee or fee based on isGasFee
            return isGasFee ? { ...item, gasFee: newFee } : { ...item, fee: newFee };
          }
          return item;
        });
    
        // Set the updated feeData
        setFeeData(updatedFeeData);
    
        // Call the debounced update function with the correct fee and gasFee values
        debouncedUpdateFee(fromCrypto?.fromCrypto, toCrypto, fee, gasFee);
      }
    },
    [transactionType, debouncedUpdateFee, feeData]
  );
  
   
  const preventInvalidKey = (e) => {
    if (e?.key === "+" || e?.key === "-") {
      e?.preventDefault();
    }
  };
  
  const renderRow = useCallback(
    (row) => {
      const isSend = transactionType === constant?.transactionType?.send;
      const isExchange = transactionType === constant?.transactionType?.exchange;
  
      // Render input with shared properties
      const renderInput = ({ value, onChange, disabled = false, additionalClasses = "" }) => (
        <input
          type="text"
          className={`pl-4 py-2 w-24 border-l-2 border-y-2 rounded-l-lg ${additionalClasses}`}
          value={value}
          onChange={onChange}
          onKeyDown={preventInvalidKey}
          disabled={disabled}
          min="0"
          max="100"
        />
      );
  
      // Render percentage box
      const renderPercentageBox = (additionalClasses = "") => (
        <Box className={`border-r-2 border-y-2 self-center py-2 pr-8 rounded-r-lg ${additionalClasses}`}>
          %
        </Box>
      );
  
      return (
        <>
          {/* First cell for crypto information */}
          <TableCell component="th" scope="row" className="w-60">
            <Box className="flex items-center gap-4">
              {getCryptoImage(row?.crypto || row?.fromCrypto, imageBaseURL)}
              <p className="text-gray-900">{row?.crypto || row?.fromCrypto || ""}</p>
            </Box>
          </TableCell>
  
          {/* Conditional rendering for Exchange */}
          {isExchange ? (
            <>
              <TableCell component="th" scope="row" className="w-60" />
              {cryptoList?.map((crypto) => {
                const fee = row?.[crypto?.crypto] || "0";
                const isDisabled = row?.fromCrypto === crypto?.crypto;
  
                return (
                  <TableCell key={crypto?.crypto} className="pl-6">
                    <Box className="flex">
                      {renderInput({
                        value: fee,
                        onChange: (e) => handleFeeChange(e, row, crypto?.crypto, false),
                        disabled: isDisabled,
                        additionalClasses: isDisabled
                          ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                          : "",
                      })}
                      {renderPercentageBox(
                        isDisabled ? "bg-gray-300 text-gray-600 cursor-not-allowed" : ""
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </>
          ) : (
            <>
              {/* Fee input for non-Exchange types */}
              <TableCell className="pl-6">
                <Box className="flex">
                  {renderInput({
                    value: row?.fee || "0",
                    onChange: (e) => handleFeeChange(e, row, null, false),
                    additionalClasses: isSend ? "rounded-r-lg border-r-2" : "",
                  })}
                  {!isSend && renderPercentageBox()}
                </Box>
              </TableCell>
  
              {/* Gas fee input for Send */}
              {isSend && (
                <TableCell>
                  <Box className="flex">
                    {renderInput({
                      value: row?.gasFee || "0",
                      onChange: (e) => handleFeeChange(e, row, null, true),
                      additionalClasses: "border-2 rounded-lg",
                    })}
                  </Box>
                </TableCell>
              )}
            </>
          )}
        </>
      );
    },
    [transactionType, imageBaseURL, handleFeeChange, cryptoList]
  );
  

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-[8px] py-2">
          {profileTypes?.map((item, index) => (
            <Box
              key={index}
              className={`cursor-pointer px-4 py-2 rounded-md
                ${feeProfileType === item?.key ? "bg-gray-200 text-gray-600" : "text-gray-400"}`}
              onClick={() => handleFeeProfileTypeChange(item)}
            >
              {item?.label}
            </Box>
          ))}
        </Box>
      </Box>

      <CommonTable headers={headers} data={feeData} renderRow={renderRow} />
      <Snackbar
        open={openToast !== ""}
        autoHideDuration={2000}
        onClose={handleCloseToast}
        message={openToast}
      />
    </Box>
  );
};

export default FeesTabs;
