import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { Box, Typography, TextField, Snackbar } from "@mui/material";
import apiRequest from "../../utils/api.js";
import moment from "moment";
import CommonTable from "../common/CommonTable.js";
import { generateBlobUrlAndDownload, getStatusComponent } from "../../utils/common.js";
import CommonButton from "../common/CommonButton.js";
import FiltersDrawer from "../common/FiltersDrawer.js";
import TransactionListFilters from "./TransactionListFilters.js";
import { useLocation } from "react-router-dom";
import { getFilterDate } from "../../utils/common.js";
import { useSelector } from "react-redux";
import CommonPagination from "../common/CommonPagination.js";
import useDebounce from "../../customHook/useDebounce.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F4F5",
    color: "#51525C",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const TransactionList = () => {
  const location = useLocation();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();
  const { fromDate, toDate } = getFilterDate(query.get("dateFilter"));

  const initialFilter = {
    type: [],
    application: "",
    cryptoCurrency: [],
    status: query.get("status") ? [query.get("status")] : [],
    fromDate: fromDate || "",
    toDate: toDate || "",
  };

  const [transactionData, setTransactionData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filters, setFilters] = useState(initialFilter);
  const [openToast, setOpenToast] = useState("");
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const [sortConfig, setSortConfig] = useState({});

  const handleDownload = async () => {
    try {
      const filtersForDownload = {
        ...filters,
        isDownload: true,
        responseType: "blob"
      }
      const res = await fetchExportedTransactions(filtersForDownload);
      if (!res?.success) return setOpenToast("Error fetching data for download");
      generateBlobUrlAndDownload(res?.data, "Transaction-History-Export", "csv" )
    } catch (error) {
      console.error("Error in handleDownload:", error?.message);
      setOpenToast("Error in handleDownload");
    }
  }

  const fetchExportedTransactions = async (filters) => {
    try {
      return await apiRequest("/get-transactions", "POST", {
        ...filters,
      });
    } catch (error) {
      console.error("Error in fetchExportedTransactions:", error?.message);
      throw new Error(error?.message);
    }
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast("");
  };

  const handleDrawer = (isOpen) => {
    setIsDrawerOpen(isOpen);
  };

  const debouncedSearchHandler = useDebounce((value) => {
    fetchTransactionList(value);
  }, 1000);

  const handleChangeSearchText = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value.length === 0 || value.length >= 3) {
      setPage(1);
    }
  };

  const fetchTransactionList = useCallback(async (searchText) => {
    try {
      const res = await apiRequest("/get-transactions", "POST", {
        page,
        searchText,
        orderBy: sortConfig?.orderBy,
        sortBy: sortConfig?.sortBy,
        ...filters,
      });
      if (res?.success) {
        setTransactionData(res?.data?.transactionsList);
        setTotalPages(res?.data?.totalCount);
      }
    } catch (err) {
      setOpenToast(`Error in fetchTransactionList: ${err.message}`);
      console.error(`Error in fetchTransactionList: ${err.message}`);
    }
  }, [page, filters, sortConfig]);

  useEffect(() => {
    if (!searchText) {
      fetchTransactionList("");
    }
    else if (page && searchText.length >= 3) {
      debouncedSearchHandler(searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTransactionList, searchText, page])

  const tableHeaders = [
    { headerItem: "Transaction Id" },
    { headerItem: "Type" },
    { headerItem: "Blockchain" },
    { headerItem: "Network" },
    { headerItem: "Status Code" },
    { headerItem: "Crypto Amount", sortValue: "cryptoAmount" },
    { headerItem: "Fiat Amount", sortValue: "fiatAmount" },
    { headerItem: "Created Date", sortValue: "createdDate" },
    { headerItem: "Application Name" },
    { headerItem: "Application Id" },
    { headerItem: "User ID" },
    { headerItem: "Trade Order Id" },
    { headerItem: "Crypto Currency" },
    { headerItem: "Fiat Currency" },
  ];
  const renderRow = (row) => {
    return (
      <>
        <StyledTableCell component="th" scope="row" className="!max-w-72">
          {row?.transactionId
            ? row?.transactionId.substring(0, 6) +
            "..." +
            row?.transactionId.substring(row?.transactionId.length - 6)
            : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          <p className="text-[#1570EF]">{row?.type ? row?.type : "NA"}</p>
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.blockchain ? row?.blockchain : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.network ? row?.network : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.status?.code ? getStatusComponent(row?.status?.code) : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.cryptoAmount ? row?.cryptoAmount?.toFixed(6) : "0.0"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.fiatAmount ? row?.fiatAmount?.toFixed(2) : "0.0"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {moment(row?.createdDate).format("DD/MM/YYYY HH:mm:ss") || "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.application?.name ? row?.application?.name : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.application?.applicationId
            ? row?.application?.applicationId
            : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.applicationUserId ? row?.applicationUserId : "NA"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.autoTrade?.tradeOrderId
            ? row?.autoTrade?.tradeOrderId.substring(0, 6) +
            "..." +
            row?.autoTrade?.tradeOrderId.substring(
              row?.autoTrade?.tradeOrderId.length - 6
            )
            : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.cryptoSymbol ? row?.cryptoSymbol : "N/A"}
        </StyledTableCell>
        <StyledTableCell align="left">
          {row?.depositFiatCurrency ? row?.depositFiatCurrency : "N/A"}
        </StyledTableCell>
      </>
    );
  };

  const handleRowClick = (row) => {
    window.open(`/transactions/${row.transactionId}`, '_blank');
  };

  const handleSorting = (orderBy, sortBy) => {
    setSortConfig({ orderBy, sortBy });
  };

  return (
    <Box className="border-[1px] rounded-lg w-full transaction-list h-[98%]">
      <Snackbar
        open={openToast ? true : false}
        autoHideDuration={3000}
        onClose={handleCloseToast}
        message={openToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <div className="flex justify-between items-center">
        <div>
          <Typography variant="h6" className="!font-semibold p-6" gutterBottom>
            Transactions History
          </Typography>
        </div>
        <div className="flex gap-2 mr-4">
          <TextField
            className="w-96"
            placeholder="Search Transactions"
            data-e2e="search-transaction"
            type="search"
            size="small"
            onChange={handleChangeSearchText}
            value={searchText}
            InputProps={{
              endAdornment: (
                <img
                  src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                  alt="search"
                  className="w-6 h-6"
                />
              ),
            }}
          />
          <Box onClick={handleDownload} className="cursor-pointer">
            <img
              src={`${imageBaseURL}/images/caas-admin-ui/icons/download_button.png`}
              alt="download-icon"
              className="w-10 h-10"
            />
          </Box>
          <CommonButton
            text="Filters"
            bgColor="white"
            onClick={() => handleDrawer(true)}
            StartIcon={
              <img
                src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                alt="filter"
                className="w-6 h-6"
              />
            }
          />
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/settings_button.png`}
            alt="settings"
            className="w-10 h-10"
          />
        </div>
      </div>
      <div className="flex flex-col h-[90%]">
        <div className="overflow-x-auto">
          <CommonTable
            headers={tableHeaders}
            data={transactionData}
            renderRow={renderRow}
            handleRowClick={handleRowClick}
            sorting={handleSorting}
            setSortConfig={setSortConfig}
            sortConfig={sortConfig}
          />
        </div>
        <div className="mt-auto border-t-[1px]">
          <CommonPagination totalPages={totalPages} setPage={setPage} page={page} /></div></div>
      <FiltersDrawer open={isDrawerOpen} onClose={() => handleDrawer(false)}>
        <TransactionListFilters
          setFilters={setFilters}
          setPage={setPage}
          handleDrawer={handleDrawer}
        />
      </FiltersDrawer>
    </Box>
  );
};

export default TransactionList;
