import { Modal } from '@mui/material';
import React from 'react';
import CommonButton from '../../../../common/CommonButton.js';
import { useSelector } from 'react-redux';
import apiRequest from '../../../../../utils/api.js';

const DeleteConfirmationModal = React.memo((props) => {
    const { id, setPage, count, page } = props;
    const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);

    const handleDelete = async () => {
        const res = await apiRequest("/delete-wallet-settings", "POST", {
            id: id,
        });
        if (res.success) {
            if (count === 1 && page > 1) {
                setPage((prevPage) => prevPage - 1); // Go to previous page if current page is empty
            }
            props?.fetchWalletSettingsList("");
            props?.onClose();
        } else {
            console?.error("Delete failed:", res?.message);
        }
    };

    return (
        <Modal open={props?.open} className="flex justify-center items-center">
            <div className="flex flex-col gap-8 bg-zinc-100 p-4 px-8 h-max rounded-xl send-Notification">
                <div className="flex gap-8">
                    <div>
                        <p className="text-xl text-zinc-900 font-semibold">
                            Are you sure, you want to delete?
                        </p>
                    </div>
                    <div className="flex justify-end">
                        <img
                            src={`${imageBaseURL}/images/caas-admin-ui/icons/close.png`}
                            alt="Close"
                            onClick={props?.onClose}
                            className="w-6 h-6 cursor-pointer hover:bg-gray-200"
                        />
                    </div>
                </div>
                <div className="flex gap-4 pl-8 pr-12">
                    <CommonButton
                        onClick={handleDelete}
                        text="Confirm"
                        bgColor="black"
                        width="w-full"
                        type = "button"
                    />
                    <CommonButton
                        text="Cancel"
                        bgColor="black"
                        width="w-full"
                        type = "button"
                        onClick={props?.onClose}
                    />
                </div>
            </div>
        </Modal>
    );
});

export default DeleteConfirmationModal;
