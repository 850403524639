import React, { useEffect, useState } from "react";
import LoginForm from "./LoginForm.js";
import LoginHeader from "./LoginHeader.js";
import { useNavigate, useLocation } from "react-router-dom";
import apiRequest from "../../utils/api.js";
import { encryptData, decryptData } from "../../utils/encryptCreds.js";
import { useSelector } from "react-redux";
import AuthenticatorModal from "./AuthenticatorModal.js";
import VerificationModal from "./VerificationModal.js";

const Login = () => {
  const navigate = useNavigate();
  const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);
  const adminLogin2FA = useSelector((state) => state?.config?.adminLogin2FA);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const redirectValue = query?.get("redirect");

  const [textInput, setTextInput] = useState({
    email: "",
    password: "",
    isChecked: false,
  });

  const [isPasswordVisible, setIsPasswordVissible] = useState(false);
  const [error, setError] = useState("");
  const [show2FAVerificationModal, setShow2FAVerificationModal] = useState(false);
  const [showAuthenticatorModal, setShowAuthenticatorModal] = useState(false);
  const [userData, setUserData] = useState();
  const [userEmail, setUserEmail] = useState();
  const authenticationDone = localStorage.getItem("authenticationDone")

  const setVerifyModal = (userData, userEmail) => {
    setShow2FAVerificationModal(true)
    setUserData(userData)
    setUserEmail(userEmail)
  }
  const setAuthenticatorModal = (userData, userEmail) => {
    setShowAuthenticatorModal(true)
    setUserData(userData)
    setUserEmail(userEmail)
  }
  const onVerificationSucess = (userData) => {
    localStorage.setItem("token", userData?.token)
    if (userData?.is2faActivated?.secret) {
      if (userData?.is2faActivated?.secret != null) {
        localStorage.setItem("2FAStatus", true);
      } else {
        localStorage.setItem("2FAStatus", false);
      }
    }
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    const isDeleted = localStorage.getItem("isDeleted");
    if (token && isDeleted === "true") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const getUserLoggedInData = await getCredentialsWithExpiry();
        if (getUserLoggedInData) {
          const currentDate = new Date();
          if (currentDate > new Date(getUserLoggedInData?.expiryDate)) {
            localStorage.removeItem("userCredentials");
          } else {
            setTextInput({
              email: getUserLoggedInData?.email,
              password: getUserLoggedInData?.password,
            });
          }
        }
      } catch (error) {
        console.error("Error in getUserLoggedInData:", error);
      }
    };
    fetchUserData();
  }, []);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setTextInput({ ...textInput, [e.target.name]: e.target.checked });
    } else {
      setTextInput({ ...textInput, [e.target.name]: e.target.value });
    }
  };
  useEffect(() => {
    if (!authenticationDone) {
      localStorage.clear();
    }
  }, [authenticationDone]);

  const handleClose = () => {
    const authenticationDone = localStorage.getItem("authenticationDone")
    if (!authenticationDone) {
      localStorage.clear();
    }
    setShow2FAVerificationModal(false);
    setShowAuthenticatorModal(false)
  }
  const setCredentialsWithExpiry = async (email, password, days) => {
    const now = new Date();
    const expiryDate = new Date(now.getTime() + days * 24 * 60 * 60 * 1000);
    const loginData = { email, password, expiryDate };
    const encryptedData = await encryptData(
      loginData,
      "12345678901234567890123456789012"
    );
    localStorage.setItem("userCredentials", JSON.stringify(encryptedData));
  };

  const getCredentialsWithExpiry = async () => {
    const getUserCredentials = localStorage.getItem("userCredentials");
    if (getUserCredentials) {
      const decryptedData = await decryptData(
        JSON.parse(getUserCredentials),
        "12345678901234567890123456789012"
      );
      return decryptedData;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (textInput?.isChecked) {
      await setCredentialsWithExpiry(textInput?.email, textInput?.password, 30);
    }
    const res = await apiRequest("/login", "POST", textInput);
    if (res?.success) {
      const isDeleted = res?.data?.isDeleted === 'true' || res?.data?.isDeleted === true;
      if (isDeleted && res?.data?.role === "ApplicationUser") {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("applicationId");
        localStorage.removeItem("isDeleted");
        localStorage.removeItem("viewPermission");
        setError("Application deleted.Login not permitted");
        navigate("/login");
        return;
      } else {
        setError("");
        localStorage.setItem("role", res?.data?.role);
        localStorage.setItem("email", res?.data?.email);
        localStorage.setItem('viewPermission', res?.data?.viewPermission);
        localStorage.setItem("applicationId", res?.data?._id);
        localStorage.setItem("isDeleted", res?.data?.isDeleted);
        const isLocalhost = window?.location?.hostname === 'localhost';
        const has2FA = (isLocalhost ? false : adminLogin2FA === "true" || adminLogin2FA === true);
        const { data } = res || {};
        const { is2faActivated, token, email } = data || {};
        if (has2FA && is2faActivated?.enabled) {
          if (!is2faActivated?.secret) {
            setAuthenticatorModal(data, email || '');
          } else {
            setVerifyModal(data, email || "");
          }
        } else {
          localStorage.setItem('token', token);
          navigate(redirectValue || "/");
        }
      }
    } else {
      setError(res?.error);
    }
  };

  return (
    <div
      className="relative h-screen flex justify-center items-center bg-cover bg-center"
      style={{ backgroundImage: `url(${imageBaseURL}/images/caas-admin-ui/login.jpg)` }}
    >
      <div className="flex flex-col items-center justify-center gap-6 border-solid border-2 h-max p-2 px-8 bg-slate-50 rounded-lg bg-opacity-85 z-10 relative">
        <LoginHeader />
        <LoginForm
          textInput={textInput}
          handleChange={handleChange}
          handleFormSubmit={handleFormSubmit}
          isPasswordVisible={isPasswordVisible}
          setIsPasswordVissible={setIsPasswordVissible}
          error={error}
        />
      </div>
      {show2FAVerificationModal &&
        <VerificationModal
          open={show2FAVerificationModal}
          onClose={handleClose}
          onVerificationSucess={onVerificationSucess}
          userData={userData}
          userEmail={userEmail}
        />
      }
      {showAuthenticatorModal &&
        <AuthenticatorModal
          open={showAuthenticatorModal}
          onClose={handleClose}
          userData={userData}
          userEmail={userEmail}
          onVerificationSucess={onVerificationSucess}
          component={"head"}
        />
      }
    </div>
  );
};
export default Login;
