import React from "react";
import { Box } from "@mui/material";
import CopyButton from "../../common/CopyButton.js";
import { getStatusComponent } from "../../../utils/common.js";
import moment from "moment";
import { useSelector } from "react-redux";
import { transactionTypeImage } from "../../../utils/common.js";
import constant from "../../../utils/constant.js";

const TransactionInfoTab = ({ data }) => {
  const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
  const junoAdminUiTransactionUrl = useSelector((state) => state.config.junoAdminUiTransactionUrl);
  const ListItem = ({ label, value, copyText }) => (
    <Box className="flex items-center justify-between">
      <p className="text-gray-600">{label}:</p>
      <div className={`${!copyText ? "p-2" : ''} text-end`}>
        {value ? value : "N/A"} {copyText && <CopyButton textToCopy={value} />}
      </div>
    </Box>
  );
  const formatAmount = (amount, decimals) => (amount > 0 ? amount?.toFixed(decimals) : "0.0");
  const parseStatusMessage = (message) => {
    if (!message) return null;
    try {
      const parsedMessage = JSON.parse(message);
      return parsedMessage;
    } catch (error) {
      return message;
    }
  };
  const parsedStatusMessage = parseStatusMessage(data?.status?.message);

  return (
    <Box className="border-[1px] rounded-lg m-6 p-6 text-sm flex flex-col gap-2">
      <ListItem
        label="Application Name"
        value={data?.application?.name}
        copyText
      />
      <ListItem
        label="Account ID"
        value={data?.application?.applicationId}
        copyText
      />
      <hr />
      <Box className="flex items-center justify-between">
        <p className="text-gray-600">Transaction Type:</p>
        <Box className="flex items-center">
          {transactionTypeImage(data?.type, imageBaseURL)}
          <span className="p-2"> {data?.type ? data?.type : "N/A"}</span>
        </Box>
      </Box>
      <hr />
      <ListItem
        label="Transaction Initiated"
        value={moment(data?.createdDate).format("DD/MM/YYYY - HH:mm:ss")}
      />
      <hr />
      <ListItem
        label="Last Updated"
        value={moment(data?.updatedDate).format("DD/MM/YYYY - HH:mm:ss")}
      />
      <hr />
      <ListItem label="Transaction Status" value={data?.status?.code} />
      <hr />
      <ListItem label="Status" value={getStatusComponent(data?.status?.code)} />
      <hr />
      <div className="flex items-center justify-between">
        <h4 className="text-gray-600 w-32">Status Message:</h4>
        <Box className="overflow-y-auto max-h-48 w-full ml-2 p-2 flex justify-end">
          {parsedStatusMessage || data?.status?.message ? (
            <pre className="text-sm whitespace-pre-wrap break-words text-right">
              {JSON.stringify(parsedStatusMessage || data?.status?.message, null, 2)}
            </pre>
          ) : (
            <p>N/A</p>
          )}
        </Box>
      </div>
      <hr />
      <ListItem label="Transaction ID" value={data?.transactionId} copyText />
      <a
        href={`${junoAdminUiTransactionUrl}/${data?.applicationRefId}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 no-underline"
      >
        <ListItem label="Juno Transaction ID" value={data?.applicationRefId} copyText />
      </a>
      <ListItem label="Blockchain" value={data?.blockchain} copyText />
      <ListItem label="Network" value={data?.network} copyText />
      {/* THE COMPONENT RENDERS FOR CASES OTHER THAN EXCHANGE. */}
      {data?.type !== constant.transactionType?.exchange && <ListItem label="Crypto ID" value={data?.cryptoSymbol} copyText />}
      <hr />
      {data?.fromWallet && (
        <>
          <ListItem
            label="From Wallet"
            value={data?.fromWallet?.address}
            copyText
          />
        </>
      )}
      {data?.toWallet && (
        <>
          <ListItem
            label="To Wallet"
            value={data?.toWallet?.address}
            copyText
          />
        </>
      )}
      {/* THE COMPONENTS RENDER ONLY FOR EXCHANGE */}
      {data?.type === constant.transactionType?.exchange && <>
        <ListItem label="From Crypto ID" value={data?.fromCrypto?.cryptoSymbol} copyText />
        <ListItem label="From Crypto Amount" value={data?.fromCrypto?.amount} copyText />
        <ListItem label="To Crypto ID" value={data?.toCrypto?.cryptoSymbol} copyText />
        <ListItem label="To Crypto Amount" value={data?.toCrypto?.amount} copyText />
      </>}
     
      {/* THE COMPONENTS RENDER FOR CASES OTHER THAN EXCHANGE. */}
      {data?.type !== constant.transactionType?.exchange && <>
        <ListItem label="Crypto Amount" value={formatAmount(data?.cryptoAmount, 6)} copyText />
        <ListItem label="Fiat Amount" value={formatAmount(data?.fiatAmount, 2)} copyText />
      </>}

      {data?.autoTrade && (
        <>
          <ListItem
            label="Trade ID"
            value={data?.autoTrade?.tradeOrderId}
            copyText
          />
          <ListItem
            label="Currency"
            value={data?.autoTrade?.sendOrReceiveCurrency}
            copyText
          />
          <ListItem
            label="Quantity"
            value={data?.autoTrade?.sendOrReceiveQuantity}
            copyText
          />
        </>
      )}
    </Box>
  );
};

export default TransactionInfoTab;
