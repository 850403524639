import React, { useEffect, useState, useCallback } from 'react';
import { CloseOutlined } from "@mui/icons-material";
import { Modal, TextField } from "@mui/material";
import CommonButton from '../common/CommonButton.js';
import apiRequest from '../../utils/api.js';

const AuthenticatorModal = (props) => {
    const { userData, userEmail } = props;
    const [error, setError] = useState('');
    const [verificationCode2fa, setVerificationCode2fa] = useState('');
    const [imageData, setImageData] = useState(null);
    const [secret, setSecret] = useState(null);

    const generateQRcode = useCallback(async () => {
        try {
            const response = await apiRequest("/generate-qrcode", "POST", {
                email: userEmail, paymentProviderName: null
            });
            if (!response?.success) throw new Error(JSON.stringify(response?.error));
            const { qrCode, secret } = response?.data;
            setImageData(qrCode);
            setSecret(secret);
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    }, [userEmail]);

    useEffect(() => {
        generateQRcode();
    }, [generateQRcode]);

    const onVerifyCode = async (e) => {
        e?.preventDefault();
        if (verificationCode2fa?.length === 6) {
            try {
                const payload = {
                    email: userEmail,
                    user2faToken: verificationCode2fa,
                    changeActivation: 'activate',
                    secret: secret?.base32,
                    encoding: 'base32',
                    authenticator: true,
                    token: userData?.token,
                };
                const data = await apiRequest("/admin-user-verify-2fa-token", "POST", payload);
                if (data?.data?.success === true) {
                    localStorage.setItem('token', userData?.token);
                    localStorage.setItem('authenticationDone', 'true');
                    if (props?.component === 'head') {
                        props?.onVerificationSucess(userData);
                    } else {
                        props?.setIs2faActivated();
                        localStorage.setItem('2FAStatus', true);
                    }
                    window.location.href = "/";
                    props?.onClose();
                } else {
                    setError('Invalid verification code!');
                }
            } catch (err) {
                console?.error(err);
            }
        } else {
            setError('Invalid token! 2FA token must have 6 digits only');
        }
    };

    const onInputKeyDown = (e) => {
        // Handle Enter key press
        if (e?.key === 'Enter') {
            e?.preventDefault();
            // Verify code only if it's valid (length is 6)
            if (verificationCode2fa?.length === 6) {
                onVerifyCode(e); // Pass event to prevent form submission
            }
        }
    };

    const disabledVerify = verificationCode2fa?.length !== 6;

    return (
        <Modal open={props?.open} onClose={props?.onClose} className="flex justify-center items-center">
            <div className="flex max-w-md bg-white p-4 px-8 h-max rounded-xl edit-balance flex-col items-center justify-center">
                <form className="flex flex-col gap-10">
                    <div className="flex justify-between w-full">
                        <p className="text-2xl text-zinc-900 font-semibold">Authenticator</p>
                        <CloseOutlined onClick={props?.onClose} className="cursor-pointer" />
                    </div>

                    <div className="theme-modal-authenticator flex justify-center items-center">
                        <img src={imageData} alt="QR Code" className="w-60 h-60 object-contain" />
                    </div>

                    <p className="theme-modal-text text-center">
                        Scan the QR Code in Google's Authenticator app and input the verification code.
                    </p>
                    <div key="verification-code">
                        <p className="font-normal text-base text-zinc-800 flex items-center">
                            Verification Code<span className="text-red-600">*</span> :
                        </p>
                        <TextField
                            className="text-field"
                            type="text"
                            value={verificationCode2fa}
                            onChange={(e) => {
                                setError('');
                                setVerificationCode2fa(e?.target?.value);
                            }}
                            placeholder="Enter 6-digit code"
                            variant="outlined"
                            size="small"
                            error={!!error}
                            helperText={error}
                            required
                            onKeyDown={onInputKeyDown} // Handle Enter key press
                        />
                    </div>
                    <div className="theme-btn-block theme-modal-submit full-width">
                        <CommonButton
                            text="Verify"
                            type="button"
                            bgColor="black"
                            width="w-48"
                            borderRadius="rounded-3xl"
                            disabled={disabledVerify}
                            onClick={(e) => {
                                if (!disabledVerify) {
                                    onVerifyCode(e); // Pass event to prevent form submission
                                }
                            }}
                        />
                    </div>
                </form>
            </div>
        </Modal>

    );
};

export default AuthenticatorModal;
