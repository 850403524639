import * as Yup from "yup";

const CreateOrUpdateWalletSettingsConfig = [
  {
    name: "crypto",
    type: "text",
    value: "",
    validation: Yup.string().required("Crypto is required"),
  },
  {
    name: "network",
    type: "text",
    value: "",
    validation: Yup.string().required("Network is required"),
  },
  {
    name: "minimumWallets",
    type: "number",
    value: 0,
    validation: Yup.number()
      .typeError("Minimum Wallets must be a number")
      .required("Minimum Wallets is required")
      .min(0, "Minimum Wallets must be greater than or equal to 0"),
  },
  {
    name: "maximumWallets",
    type: "number",
    value: 0,
    validation: Yup.number()
      .typeError("Maximum Wallets must be a number")
      .required("Maximum Wallets is required")
      .min(0, "Maximum Wallets must be greater than or equal to 0")
      .test(
        "is-greater-than-min",
        "Maximum Wallets must be greater than or equal to Minimum Wallets",
        function (value) {
          const { minimumWallets } = this.parent;
          // Ensure both values are set before comparing
          return value >= (minimumWallets || 0);
        }
      ),
  },
  {
    name: "minCapacityThreshold",
    type: "number",
    value: 0,
    validation: Yup.number()
      .typeError("Minimum Capacity Threshold must be a number")
      .required("Minimum Capacity Threshold is required")
      .min(0, "Minimum Capacity Threshold must be greater than or equal to 0")
      .test(
        "min-lt-max-threshold",
        "Minimum Capacity Threshold must be less than or equal to Maximum Capacity Threshold",
        function (value) {
          const { maxCapacityThreshold } = this.parent;
          // Ensure both values are set before comparing
          return value <= maxCapacityThreshold ;
        }
      ),
  },
  {
    name: "maxCapacityThreshold",
    type: "number",
    value: 0,
    validation: Yup.number()
      .typeError("Maximum Capacity Threshold must be a number")
      .required("Maximum Capacity Threshold is required")
      .min(0, "Maximum Capacity Threshold must be greater than or equal to 0")
      .test(
        "is-greater-than-min",
        "Maximum Capacity Threshold must be greater than or equal to Minimum Capacity Threshold",
        function (value) {
          const { minCapacityThreshold } = this.parent;
          // Ensure both values are set before comparing
          return value >= (minCapacityThreshold || 0);
        }
      ),
  },
];

export default CreateOrUpdateWalletSettingsConfig;
