//@ts-check
import * as Yup from "yup";

const TransferWalletBalanceSchema = [
  {
    name: "wallet address",
    type: "text",
    value: "",
    validation: Yup.string().required("Value is required"),
  },
  {
    name: "amount",
    type: "number",
    value: 0,
    validation: Yup.number().typeError('Field value must be a number').required("Value is required")
     .min(0, "Value must be greater than or equal to 0"),
  },
 
];

export default  TransferWalletBalanceSchema;
