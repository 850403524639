import React, { useMemo, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  tableCellClasses,
  styled,
} from "@mui/material";
import CommonTable from "../../common/CommonTable.js";
import moment from "moment";
import { AddCircleRounded, RemoveCircleRounded } from "@mui/icons-material";

const StyledTableCellWrapper = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    background: "#FAFAFA",
    color: "#51525C",
  },
}));

const EventLogTab = ({ data }) => {
  const [expandedShows, setExpandedShows] = useState(null);

  const tableHeaders = [
    { headerItem: null },
    { headerItem: "Event Type" },
    { headerItem: "Timestamp" },
  ];

  const parseAndFormatJson = (jsonString) => {
    if (!jsonString) return "";

    try {
      const parsedObject = JSON.parse(jsonString);
      return typeof parsedObject === "string"
        ? parsedObject
        : JSON.stringify(parsedObject, null, 2);
    } catch (error) {
      return jsonString;
    }
  };

  const dataSource = useMemo(() => {
    if (!Array.isArray(data) || !data.length) return [];

    return data.map((log, index) => ({
      key: index + 1,
      eventId: log?._id ?? null,
      type: log?.code ?? "NA",
      date: log?.updatedDate
        ? moment(log.updatedDate).format("DD.MM.YY HH:mm")
        : "NA",
        message: parseAndFormatJson(log?.message),
    }));
  }, [data]);

  const renderRow = (row) => {
    if (!row) return null;

    const isRowOpen = expandedShows === row?.eventId;
    const toggleRow = () =>
      setExpandedShows((prev) => (prev === row?.eventId ? null : row?.eventId));

    const showIcon = row?.message && row.message?.length > 0;

    return (
      <>
        <TableCell>
          {showIcon && (
            <IconButton onClick={toggleRow}>
              {isRowOpen ? <RemoveCircleRounded /> : <AddCircleRounded />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.type || "NA"}
        </TableCell>
        <TableCell align="left">{row?.date || "NA"}</TableCell>
      </>
    );
  };

  const ExpandedRowRender = ({ record }) => (
    <StyledTableCellWrapper colSpan={7}>
      <Collapse in={!!record?.message} timeout="auto" unmountOnExit>
        <div className="flex justify-end">
          <div className="w-full md:!w-[85%] whitespace-pre-wrap py-3 px-auto text-base font-mono">
            {record?.message || ""}
          </div>
        </div>
      </Collapse>
    </StyledTableCellWrapper>
  );

  const expandedRecord = useMemo(
    () => dataSource.find((item) => item?.eventId === expandedShows),
    [dataSource, expandedShows]
  );

  return (
    <Box>
      <p className="font-semibold text-lg mt-8 px-6 pb-6">Event Log</p>
      <CommonTable
        headers={tableHeaders}
        data={dataSource}
        renderRow={renderRow}
        collapseId={expandedShows}
        collapseComponent={<ExpandedRowRender record={expandedRecord} />}
      />
    </Box>
  );
};

export default EventLogTab;
