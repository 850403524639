import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { Box, IconButton, Typography, TextField } from "@mui/material";
import CommonTable from "../../../../common/CommonTable.js";
import apiRequest from "../../../../../utils/api.js";
import CommonPagination from "../../../../common/CommonPagination.js";
import CommonButton from "../../../../common/CommonButton.js";
import WalletSettingsFilters from "./WalletSettingsFilters.js"; // Updated filters component
import DeleteConfirmationModal from "./DeleteWalletSettings.js";
import CreateOrUpdateWalletSettings from "./CreateOrUpdateWalletSettings.js";
import FiltersDrawer from "../../../../common/FiltersDrawer.js";
import useDebounce from "../../../../../customHook/useDebounce.js";
import { isViewPermissionValid } from "../../../../../utils/common.js";
/**
 * Component for displaying and managing wallet settings.
 * Features include search, filters, sorting, pagination, and row expansion for additional details.
 */
const WalletSettings = ({ applicationId }) => {
    // Grouping state variables into logical categories
    const [pagination, setPagination] = useState({
        page: 1,
        totalPages: 1,
    });

    const [walletSettingsData, setWalletSettingsData] = useState({
        walletSettingsList: [],
        cryptoCurrencyList: [],
        walletSettingsId: "",
        updateWalletSettingData: {},
    });

    const [uiState, setUiState] = useState({
        isDrawerOpen: false,
        deleteDialogToggle: false,
        open: false,
    });

    const [searchText, setSearchText] = useState("");

    const [sortState, setSortState] = useState({
        sortConfig: { orderBy: "desc", sortBy: "" },
        filters: {},
    });

    const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);

    const networks = [
        { id: 1, network: "testnet" },
        { id: 2, network: "mainnet" },
    ];

    // Custom styled table cell for consistent appearance
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#F4F4F5",
            color: "#51525C",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        textAlign: "left",
    }));

    // Handler for opening the update modal
    const onOpen = (item) => {
        setWalletSettingsData((prev) => ({
            ...prev,
            updateWalletSettingData: item,
        }));
        setUiState((prev) => ({
            ...prev,
            open: true,
        }));
    };

    // Handler for closing the update modal
    const onClose = () => {
        setWalletSettingsData((prev) => ({
            ...prev,
            updateWalletSettingData: {},
        }));
        setUiState((prev) => ({
            ...prev,
            open: false,
        }));
    };

    // Close delete wallet settings modal
    const closeDeleteWalletSettingsModal = () => {
        setUiState((prev) => ({
            ...prev,
            deleteDialogToggle: false,
        }));
    };

    // Handle delete wallet setting
    const handleDeleteWalletSetting = (id) => {
        setWalletSettingsData((prev) => ({
            ...prev,
            walletSettingsId: id,
        }));
        setUiState((prev) => ({
            ...prev,
            deleteDialogToggle: true,
        }));
    };

    const getCryptoList = useCallback(async () => {
        try {
            const res = await apiRequest("/list-crypto", "POST", {});
            if (!res?.success) throw new Error(JSON.stringify(res?.error));
            setWalletSettingsData((prev) => ({
                ...prev,
                cryptoCurrencyList: res?.data?.CryptoSettingList || [],
            }));
        } catch (error) {
            console.error("Error: ", error);
        }
    }, []);

    useEffect(() => {
        getCryptoList();
    }, [getCryptoList]);

    const fetchWalletSettingsList = useCallback(async (searchText) => {
        try {
            if (searchText?.length >= 3 || searchText?.length === 0) {
                const res = await apiRequest("/get-wallet-settings", "POST", {
                    applicationId,
                    page: pagination?.page,
                    searchText: searchText || "",
                    orderBy: sortState?.sortConfig?.orderBy || "",
                    sortBy: sortState?.sortConfig?.sortBy || "",
                    ...sortState?.filters,
                });

                if (!res.success) throw new Error(JSON.stringify(res?.error));

                const result = res?.data;
                setPagination((prev) => ({
                    ...prev,
                    totalPages: result?.totalCount,
                }));
                setWalletSettingsData((prev) => ({
                    ...prev,
                    walletSettingsList: result?.walletSettingsList || [],
                }));
            }
        } catch (error) {
            console.error("Error fetching wallet settings:", error);
        }
    }, [pagination?.page, sortState?.filters, sortState?.sortConfig, applicationId]);

    useEffect(() => {
        fetchWalletSettingsList("");
    }, [fetchWalletSettingsList]);

    // Debounced search handler that waits before making the API call
    const searchTextHandler = useDebounce((value) => {
        fetchWalletSettingsList(value);
    }, 1000);

    const handleChangeSearchText = (e) => {
        const value = e?.target?.value;
        setSearchText(value); 
        searchTextHandler(value);
    };

    const handleSorting = (orderBy, sortBy) => {
        setSortState((prev) => ({
            ...prev,
            sortConfig: { orderBy, sortBy },
        }));
    };

    const tableHeaders = [
        { headerItem: "Crypto", sortValue: "crypto" },
        { headerItem: "Network", sortValue: "network" },
        { headerItem: "Min. Wallets", sortValue: "minimumWallets" },
        { headerItem: "Max. Wallets", sortValue: "maximumWallets" },
        { headerItem: "Min. Threshold", sortValue: "minCapacityThreshold" },
        { headerItem: "Max. Threshold", sortValue: "maxCapacityThreshold" },
        { headerItem: "Action" },
    ];

    const displayValue = (value) => (value !== undefined && value !== null ? value : "NA");

    const renderRow = (row) => {
        return (
            <>
                <StyledTableCell>{row?.crypto?.symbol || "NA"}</StyledTableCell>
                <StyledTableCell>{row?.network || "NA"}</StyledTableCell>
                <StyledTableCell>{displayValue(row?.minimumWallets)}</StyledTableCell>
                <StyledTableCell>{displayValue(row?.maximumWallets)}</StyledTableCell>
                <StyledTableCell>{displayValue(row?.minCapacityThreshold)}</StyledTableCell>
                <StyledTableCell>{displayValue(row?.maxCapacityThreshold)}</StyledTableCell>
                <StyledTableCell>
                    <IconButton onClick={isViewPermissionValid ? undefined: () => onOpen(row)}>
                        <img
                            src={`${imageBaseURL}/images/caas-admin-ui/icons/edit.png`}
                            alt="edit-icon"
                            className="w-5 h-5"
                        />
                    </IconButton>
                    <IconButton onClick={isViewPermissionValid? undefined: () => handleDeleteWalletSetting(row?._id)}>
                        <img
                            src={`${imageBaseURL}/images/caas-admin-ui/icons/delete.png`}
                            alt="delete-icon"
                            className="w-5 h-5"
                        />
                    </IconButton>
                </StyledTableCell>
            </>
        );
    };

    return (
        <Box>
            <div className="flex justify-between items-center">
                <Typography variant="h6" className="!font-semibold p-6" gutterBottom>
                    Wallet Settings
                </Typography>
                <div className="flex gap-2 mr-4">
                    <CommonButton
                        text="Add Wallet Settings"
                        bgColor="black"
                        type="button"
                        onClick={isViewPermissionValid? undefined: () => onOpen({})}
                        disabled={isViewPermissionValid}
                        StartIcon={
                            <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/plus_button.png`}
                                alt="add-icon"
                                className="w-6 h-6"
                            />
                        }
                    />
                    <TextField
                        className="w-96"
                        placeholder="Search Wallet Settings"
                        data-e2e="search-wallet-settings"
                        type="search"
                        size="small"
                        onChange={handleChangeSearchText}
                        value={searchText}
                        InputProps={{
                            endAdornment: (
                                <img
                                    src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                                    alt="search"
                                    className="w-6 h-6"
                                />
                            ),
                        }}
                    />
                    <CommonButton
                        text="Filters"
                        type="button"
                        bgColor="white"
                        onClick={() => setUiState((prev) => ({ ...prev, isDrawerOpen: true }))}
                        StartIcon={
                            <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                                alt="filter"
                                className="w-6 h-6"
                            />
                        }
                    />
                </div>
            </div>
            <div className="flex flex-col h-[74vh]">
                <div className="overflow-x-auto">
                    <CommonTable
                        headers={tableHeaders}
                        renderRow={renderRow}
                        data={walletSettingsData?.walletSettingsList?.map((item) => ({ id: item?._id, ...item }))}
                        sorting={handleSorting}
                        sortConfig={sortState?.sortConfig}
                        setSortConfig={setSortState}
                    />
                </div>
                <div className="mt-auto border-t-[1px]">
                    <CommonPagination
                        totalPages={pagination?.totalPages}
                        setPage={(page) => setPagination((prev) => ({ ...prev, page }))}
                        page={pagination?.page}
                    />
                </div>
            </div>
            <CreateOrUpdateWalletSettings
                open={uiState.open}
                onClose={onClose}
                cryptos={walletSettingsData?.cryptoCurrencyList}
                networks={networks}
                applicationId={applicationId}
                fetchWalletSettingsList={fetchWalletSettingsList}
                updateWalletSettingData={walletSettingsData?.updateWalletSettingData}
            />
            {uiState?.deleteDialogToggle && (
                <DeleteConfirmationModal
                    open={uiState?.deleteDialogToggle}
                    onClose={closeDeleteWalletSettingsModal}
                    fetchWalletSettingsList={fetchWalletSettingsList}
                    id={walletSettingsData?.walletSettingsId}
                />
            )}
            <FiltersDrawer
                open={uiState?.isDrawerOpen}
                onClose={() => setUiState((prev) => ({ ...prev, isDrawerOpen: false }))}
            >
                <WalletSettingsFilters
                    setFilters={(filters) => setSortState((prev) => ({ ...prev, filters }))}
                    closeDrawer={() => setUiState((prev) => ({ ...prev, isDrawerOpen: false }))}
                    setPage={(page) => setPagination((prev) => ({ ...prev, page }))}
                />
            </FiltersDrawer>
        </Box>
    );
};

export default WalletSettings;
