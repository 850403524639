import React, { useState } from 'react';
import CommonButton from '../../common/CommonButton.js';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import apiRequest from '../../../utils/api.js';
import { useParams } from 'react-router-dom';
import moment from "moment";
import CommonNoteModal from '../CommonNoteModal.js';
import { setTransactionNotes } from '../../../redux/actions/commonAction.js';
import { isViewPermissionValid } from '../../../utils/common.js';
import { isValidDateValue } from '@testing-library/user-event/dist/utils/index.js';

const Notes = React.memo((props) => {
    const [openNoteModal, setopenNoteModal] = useState(false);
    const [openEditNoteModal, setOpenEditNoteModal] = useState(false);
    const [note, setNote] = useState("");
    const [editNote, setEditNote] = useState("");
    const [selectedNoteId, setSelectedNoteId] = useState(null);

    const dispatch = useDispatch();
    const params = useParams();
    const imageBaseURL = useSelector((state) => state.config.imageBaseUrl);
    const userRole = localStorage.getItem("role");
    const transactionNotes = useSelector(
        (state) => state?.common?.allNotes
    );
    const SUPER_ADMIN = "SuperAdmin";

    const handleNoteModal = () => {
        setopenNoteModal(!openNoteModal);
    };

    const handleEditNoteModal = (noteId, noteText) => {
        setSelectedNoteId(noteId);
        setEditNote(noteText);
        setOpenEditNoteModal(!openEditNoteModal)
    }

    const handleChange = (e) => {
        setNote(e.target.value);
    };

    const handleEditChange = (e) => {
        setEditNote(e.target.value);
    };

    const saveTransactionNote = async () => {
        const body = {
            transactionId: params?.id,
            note: note,
        };
        const res = await apiRequest(
            "/save-transaction-notes",
            "POST",
            body
        );
        if (res.success) {
            setNote("");
            dispatch(setTransactionNotes(res.data));
            setopenNoteModal(false);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await saveTransactionNote();
    };

    const handleDeleteNote = async (noteId) => {
        try {
            const response = await apiRequest("/delete-transaction-notes", "POST", {
                transactionId: params?.id,
                noteId,
            });
            if (response.success) {
                dispatch(setTransactionNotes(response.data));
            }
        } catch (err) {
            console.error(`Error in handlDeleteNote: ${err.message}`);
        }
    };

    const handleEditFormSubmit = async (e) => {
        try {
            e.preventDefault();
            const response = await apiRequest("/edit-transaction-notes", "POST", {
                transactionId: params?.id,
                noteId: selectedNoteId,
                note: editNote,
            });
            if (response.success) {
                dispatch(setTransactionNotes(response.data));
                setOpenEditNoteModal(false);
            }
        } catch (err) {
            console.error(`Error in handleEditNote: ${err.message}`);
        }
    }

    return (
        <Box>
            <div className='flex justify-between mr-6 items-center'>
                <div className='m-8 font-semibold text-xl'>Notes</div>
                {userRole === SUPER_ADMIN && (
                    <div className=''>
                        <CommonButton
                            text="Add note"
                            bgColor="black"
                            onClick={isViewPermissionValid ? undefined : handleNoteModal}
                            disabled={isValidDateValue}
                            StartIcon={
                                <img
                                    src={`${imageBaseURL}/images/caas-admin-ui/icons/plus_button.png`}
                                    alt="add-icon"
                                    className="w-6 h-6"
                                />
                            }
                        />
                        {openNoteModal &&
                            (<CommonNoteModal
                                open={openNoteModal}
                                close={handleNoteModal}
                                title="Add Note"
                                note={note}
                                handleChange={handleChange}
                                fetchSavedNotes={props.fetchSavedNotes}
                                handleFormSubmit={handleFormSubmit}
                            />
                            )}
                    </div>
                )}
            </div>

            <div>
                {transactionNotes?.notes?.notes && transactionNotes?.notes?.notes?.length > 0 ? (
                    transactionNotes?.notes?.notes.map((data) => {
                        return (
                            <div key={data?._id}>
                                <hr className='w-[97%] ml-6 mb-4' />
                                <div className='flex justify-between my-6'>
                                    <div className='ml-6'>
                                        <pre>{data?.note}</pre>
                                        <span className="text-gray-500"> Added by {data?.createdBy} </span> - <span className="text-gray-500">{moment(data?.updatedDate).format("DD/MM/YYYY - HH:mm:ss")}</span></div>
                                    {userRole === SUPER_ADMIN && (
                                        <div className='flex items-center mr-6 gap-6'>
                                            <img
                                                src={`${imageBaseURL}/images/caas-admin-ui/icons/edit.png`}
                                                alt="edit-icon"
                                                className='w-6 h-6 cursor-pointer items-center'
                                                onClick={() => handleEditNoteModal(data?._id, data?.note)} />
                                            {openEditNoteModal &&
                                                (<CommonNoteModal
                                                    open={openEditNoteModal}
                                                    close={() => setOpenEditNoteModal(false)}
                                                    title="Edit Note"
                                                    handleFormSubmit={handleEditFormSubmit}
                                                    note={editNote}
                                                    handleChange={handleEditChange}
                                                />)}
                                            <img
                                                src={`${imageBaseURL}/images/caas-admin-ui/icons/delete.png`}
                                                alt="delete-icon"
                                                className='w-6 h-6 cursor-pointer'
                                                onClick={() => handleDeleteNote(data?._id)} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className='flex justify-center'>No notes available</div>
                )}
            </div>
        </Box >
    )
});

export default Notes