import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles/index.js";
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { Box, IconButton, Typography, TextField } from "@mui/material";
import CommonTable from "../../../../common/CommonTable.js";
import moment from "moment/moment.js";
import AuditTrailDetails from "./AuditTrailDetails.js";
import apiRequest from "../../../../../utils/api.js";
import CommonPagination from "../../../../common/CommonPagination.js";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import CommonButton from "../../../../common/CommonButton.js";
import AuditTrailListFilters from "./AuditTrailFilters.js";
import FiltersDrawer from "../../../../common/FiltersDrawer.js";

/**
 * Component for displaying and managing audit trails.
 * Features include search, filters, sorting, pagination, and row expansion for additional details.
 */
const AuditTrails = (props) => {
    const { applicationId } = props
    const [page, setPage] = useState(1);
    const [openEventId, setOpenEventId] = useState(null); // Currently opened row's eventId
    const [auditTrailList, setAuditTrailList] = useState([]); // List of audit trail items
    const [totalPages, setTotalPages] = useState(0); // Total count of audit trails
    const [searchText, setSearchText] = useState(""); // Search text input value
    const [debouncedSearch, setDebouncedSearch] = useState(""); // Debounced search text for API calls
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Filters drawer state
    const [filters, setFilters] = useState({}); // Applied filters
    const [sortConfig, setSortConfig] = useState({ orderBy: "desc", sortBy: "" }); // Sorting configuration

    const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl); // Base URL for images

    // Custom styled table cell for consistent appearance
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#F4F4F5",
            color: "#51525C",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
        textAlign: "left",
    }));

    /**
     * Handles sorting updates.
     * @param {string} orderBy - Sorting order (asc/desc).
     * @param {string} sortBy - Field to sort by.
     */
    const handleSorting = (orderBy, sortBy) => {
        setSortConfig({ orderBy, sortBy });
    };

    /**
     * Handles changes to the search text.
     * @param {object} e - Event object from the input field.
     */
    const handleChangeSearchText = (e) => {
        setSearchText(e?.target?.value);
    };

    // Debounces the search input to optimize API calls
    useEffect(() => {
        if (searchText.length >= 3 || searchText === "") {
            setPage(1);
            const handler = setTimeout(() => {
                setDebouncedSearch(searchText);
            }, 1000);
            return () => clearTimeout(handler);
        }
    }, [searchText]);

    /**
     * Fetches the list of audit trails.
     * Updates the audit trail list and total count.
     */
    const fetchAuditTrailList = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem("token");
            const res = await apiRequest("/get-audit-trails", "POST", {
                token: accessToken,
                applicationId: applicationId,
                page: page,
                searchText: debouncedSearch,
                orderBy: sortConfig?.orderBy,
                sortBy: sortConfig?.sortBy,
                ...filters,
            });

            if (!res.success) throw new Error(JSON.stringify(res?.error));

            const result = res?.data;
            setTotalPages(result?.totalCount || 0);
            setAuditTrailList(result?.auditTrailList || []);
        } catch (error) {
            console.error("Error fetching audit trails:", error);
        }
    }, [page, debouncedSearch, filters, sortConfig, applicationId]);

    useEffect(() => {
        fetchAuditTrailList();
    }, [fetchAuditTrailList]);

    // Table headers with sort values
    const tableHeaders = [
        { headerItem: "Event Type", sortValue: "eventType" },
        { headerItem: "Event ID", sortValue: "eventId" },
        { headerItem: "Account ID", sortValue: "userId" },
        { headerItem: "IP Address", sortValue: "ipAddress" },
        { headerItem: "Component", sortValue: "component" },
        { headerItem: "Created At", sortValue: "createdDate" },
        { headerItem: "" },
    ];

    /**
     * Renders a table row.
     * @param {object} row - Data for the row.
     * @returns JSX element representing the row.
     */
    const renderRow = (row) => {
        const isRowOpen = openEventId === row?.eventId;

        return (
            <>
                <StyledTableCell>{row?.eventType || "NA"}</StyledTableCell>
                <StyledTableCell>{row?.eventId || "NA"}</StyledTableCell>
                <StyledTableCell>{row?.userId || "NA"}</StyledTableCell>
                <StyledTableCell>{row?.ipAddress || "NA"}</StyledTableCell>
                <StyledTableCell>{row?.pageUrl || "NA"}</StyledTableCell>
                <StyledTableCell>
                    {row?.createdDate ? moment(row?.createdDate).format("DD/MM/YY HH:mm") : "NA"}
                </StyledTableCell>
                <StyledTableCell>
                    <IconButton onClick={() => setOpenEventId((prev) => (prev === row?.eventId ? null : row?.eventId))}>
                        {isRowOpen ? <ExpandMoreOutlined /> : <ExpandLessOutlined />}
                    </IconButton>
                </StyledTableCell>
            </>
        );
    };

    return (
        <Box>
            <div className="flex justify-between items-center">
                <Typography variant="h6" className="!font-semibold p-6" gutterBottom>
                    Audit Trail
                </Typography>
                <div className="flex gap-2 mr-4">
                    <TextField
                        className="w-96"
                        placeholder="Search Error Logs"
                        data-e2e="search-transaction"
                        type="search"
                        size="small"
                        onChange={handleChangeSearchText}
                        value={searchText}
                        InputProps={{
                            endAdornment: (
                                <img
                                    src={`${imageBaseURL}/images/caas-admin-ui/icons/search.png`}
                                    alt="search"
                                    className="w-6 h-6"
                                />
                            ),
                        }}
                    />
                    <CommonButton
                        text="Filters"
                        bgColor="white"
                        onClick={() => setIsDrawerOpen(true)}
                        StartIcon={
                            <img
                                src={`${imageBaseURL}/images/caas-admin-ui/icons/filter_button.png`}
                                alt="filter"
                                className="w-6 h-6"
                            />
                        }
                    />
                </div>
            </div>
            <div className="flex flex-col h-[74vh]">
                <div className="overflow-x-auto">
                    <CommonTable
                        headers={tableHeaders}
                        renderRow={renderRow}
                        data={auditTrailList?.map((item) => ({ id: item?._id, ...item }))}
                        collapseId={openEventId}
                        sorting={handleSorting}
                        sortConfig={sortConfig}
                        setSortConfig={setSortConfig}
                        collapseComponent={<AuditTrailDetails auditTrailObject={auditTrailList?.find((item) => item?.eventId === openEventId)} />}
                    />
                </div>
                <div className="mt-auto border-t-[1px]">
                    <CommonPagination totalPages={totalPages} setPage={setPage} page={page} />
                </div>
            </div>
            <FiltersDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                <AuditTrailListFilters
                    setFilters={setFilters}
                    closeDrawer={() => setIsDrawerOpen(false)}
                    setPage={setPage}
                />
            </FiltersDrawer>
        </Box>
    );
};

export default AuditTrails;
