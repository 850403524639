import React, { useState } from 'react';
import apiRequest from '../../utils/api.js';
import { CloseOutlined } from "@mui/icons-material";
import { Modal, TextField } from "@mui/material";
import CommonButton from '../common/CommonButton.js';  // Importing the CommonButton component
import { useLocation, useNavigate } from "react-router-dom";

const VerificationModal = (props) => {
    const navigate = useNavigate();
    const { userData, userEmail } = props;
    const [error, setError] = useState('');
    const [verificationCode2fa, setVerificationCode2fa] = useState('');
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const redirectValue = query?.get("redirect");

    const onVerifyCode = async () => {
        if (verificationCode2fa?.length === 6) {
            try {
                const payload = {
                    email: userEmail,
                    authenticator: false,
                    is2faActivated: props?.is2faActivated || false,
                    token: userData?.token,
                    user2faToken: verificationCode2fa,
                };
                const data = await apiRequest("/admin-user-verify-2fa-token", "POST", payload);
                if (data?.data?.success) {
                    localStorage.setItem('token', userData?.token);
                    localStorage.setItem('authenticationDone', 'true');
                    if (props?.is2faActivated) {
                        localStorage.setItem('2FAStatus', false);
                        props?.setIs2faActivated();
                    } else {
                        props?.onVerificationSucess(userData);
                    }
                    if (redirectValue) {
                        navigate(redirectValue);
                    } else {
                        navigate("/");
                    }
                    props.onClose();

                } else {
                    setError('Invalid verification code!');
                }
            } catch (err) {
                localStorage.clear();
                console.error(err);
            }
        } else {
            setError('Invalid token! 2FA token must have 6 digits only');
        }
    };

    const onInputKeyDown = (e) => {
        // Handle Enter key press
        if (e?.key === 'Enter') {
            e?.preventDefault();
            // Verify code only if it's valid (length is 6)
            if (verificationCode2fa?.length === 6) {
                onVerifyCode();
            }
        }
    };

    const disabledVerify = verificationCode2fa.length !== 6;

    return (
        <Modal open={props?.open} onClose={props?.onClose} className="flex justify-center items-center">
            <div className="flex max-w-md bg-white p-4 px-8 h-max rounded-xl edit-balance">
                <form className="flex flex-col gap-10">
                    <div className="flex justify-between">
                        <p className="text-2xl text-zinc-900 font-semibold">2FA Verification</p>
                        <CloseOutlined onClick={props.onClose} className="cursor-pointer" />
                    </div>

                    <p className="theme-modal-text mb t-center">
                        Open Google Authenticator app and enter the secret key to continue
                    </p>

                    <div key="verification-code" className="theme-modal-field">
                        <p className="font-normal text-base text-zinc-800 flex items-center">
                            Verification Code<span className="text-red-600">*</span> :
                        </p>
                        <TextField
                            className="text-field"
                            type="text"
                            value={verificationCode2fa}
                            onChange={(e) => {
                                setError('');
                                setVerificationCode2fa(e?.target?.value);
                            }}
                            placeholder="Enter 6-digit code"
                            variant="outlined"
                            size="small"
                            error={!!error}
                            helperText={error}
                            required
                            onKeyDown={onInputKeyDown} // Handle Enter key press
                        />
                    </div>

                    <div className="theme-btn-block theme-modal-submit full-width">
                        <CommonButton
                            text="Verify"
                            type="button"
                            bgColor="black"
                            width="w-48"
                            borderRadius="rounded-3xl"
                            disabled={disabledVerify}
                            onClick={() => {
                                if (!disabledVerify) {
                                    onVerifyCode();
                                }
                            }}
                        />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default VerificationModal;
