import React from 'react'
import TableCell, { tableCellClasses } from "@mui/material/TableCell/index.js";
import { styled } from "@mui/material/styles/index.js";
import { Collapse } from '@mui/material';

export default function CryptoCurrencySweepLogDetails(props) {
    const { auditTrailObject } = props;
    const StyledTableCellWrapper = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            background: "#FAFAFA",
            color: "#51525C"
        },
    }));

    const auditTrailObjectCheck = Object.keys((auditTrailObject !== null && typeof auditTrailObject === 'object') ? auditTrailObject : {})
    return (
        <StyledTableCellWrapper
            style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }}
            colSpan={7}
        >
            <Collapse in={Boolean(auditTrailObjectCheck?.length)} timeout="auto" unmountOnExit>
                <div className="shadow-md px-6 py-4">
                    <pre>{JSON.stringify(auditTrailObject || {}, null, 7)}</pre>
                </div>
            </Collapse>
        </StyledTableCellWrapper>
    );
}
