import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import CommonDatePicker from "../../../../common/CommonDatePicker.js";
import FilterTextField from "../../../../common/FilterTextField.js";
import { useSelector } from "react-redux";

/**
 * Component for applying filters to the Audit Trail list, including options for event type, event ID, account ID, IP address, and date range.
 * @param {Object} props - The component props.
 * @param {Function} props.setFilters - Function to set filters in the parent component.
 * @param {Function} props.closeDrawer - Function to close the filter drawer.
 * @returns {JSX.Element} AuditTrailListFilters component
 */
const AuditTrailListFilters = ({ setFilters, closeDrawer, setPage }) => {
  const [inputFields, setInputFields] = useState({
    eventType: "",
    eventId: "",
    userId: "",
    ipAddress: "",
    fromDate: "",
    toDate: "",
  });

  const imageBaseURL = useSelector((state) => state?.config?.imageBaseUrl);

  /**
   * Handles changes in filter input fields and updates the state.
   * @param {Object} e - The change event object.
   */
  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setInputFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /**
   * Submits the current filters and closes the drawer.
   */
  const onSubmit = () => {
    setPage(1)
    setFilters(inputFields);
    closeDrawer();
  };

  /**
   * Resets all filter fields to their initial state and clears the filters.
   */
  const reset = () => {
    setFilters({});
    setInputFields({
      eventType: "",
      eventId: "",
      userId: "",
      ipAddress: "",
      fromDate: null,
      toDate: null,
    });
  };

  /**
   * Updates the date range for filtering.
   * @param {string} startDate - The start date of the range.
   * @param {string} endDate - The end date of the range.
   */
  const setDateRange = (startDate, endDate) => {
    setInputFields((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
    }));
  };

  return (
    <Box className="flex flex-col justify-between h-full">
      <Box className="mt-8 filter-drawer flex gap-4">
        <FilterTextField
          label="Event Type"
          name="eventType"
          placeholder="Search by event type"
          handleInputChange={handleInputChange}
          value={inputFields?.eventType}
        />
        <FilterTextField
          label="Event ID"
          name="eventId"
          placeholder="Search by event ID"
          handleInputChange={handleInputChange}
          value={inputFields?.eventId}
        />
        <FilterTextField
          label="Account ID"
          name="userId"
          placeholder="Search by account ID"
          handleInputChange={handleInputChange}
          value={inputFields?.userId}
        />
        <FilterTextField
          label="IP Address"
          name="ipAddress"
          placeholder="Search by IP Address"
          handleInputChange={handleInputChange}
          value={inputFields?.ipAddress}
        />
        <Box>
          <Typography variant="body2" className="mb-2 font-normal text-[#51525C]">
            Created At
          </Typography>
          <CommonDatePicker
            setDateRange={setDateRange}
            startDate={inputFields?.fromDate}
            endDate={inputFields?.toDate}
          />
        </Box>
      </Box>
      <Box className="flex self-end gap-2">
        <Button size="large" variant="outlined" id="reset" onClick={reset}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/reload_button.png`}
            alt="Reload Icon"
            className="w-6 h-6 mr-2"
          />
          Reset
        </Button>
        <Button size="large" variant="contained" id="search" onClick={onSubmit}>
          <img
            src={`${imageBaseURL}/images/caas-admin-ui/icons/search_button.png`}
            alt="Search Icon"
            className="w-6 h-6 mr-2"
          />
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default AuditTrailListFilters;
